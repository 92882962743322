import React, { useState, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { io, Socket } from "socket.io-client";
import { last } from "lodash";

const Root = styled.div`
  background-color: #b1b8c7;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const ImageComp = styled.div<{ source: string }>`
  background-image: ${({ source }) => "url(" + source + ")"};
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Content = styled.div`
  width: 100px;
  height: 100px;
`;

interface Media {
  id: string;
  code: string;
  mediaType: string;
  time: number;
  source: string;
}

export default function Home() {
  const socket = React.useRef<Socket>();
  const [socketReconnected, setSocketReconnected] = useState(false);

  const defaultImage =
    "https://www.shutterstock.com/image-photo/3d-image-omen-signal-600w-2253698865.jpg";
  const [media, setMedia] = useState<Media | null>(null);

  const timer = useRef<NodeJS.Timer>();

  React.useEffect(() => {
    if (socket.current) return;
    initSocket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMessage = (_media: Media) => {
    clearInterval(timer.current);
    timer.current = setInterval(() => {
      console.log("DEFAULT");
      setMedia(null);
    }, _media.time * 1000 + 5000);

    const img = new Image();
    img.src = _media.source;

    img.onload = () => {
      setMedia(_media);
      setTimeout(() => {
        socket.current?.emit("messageFromClient", {
          id: _media.id,
          code: _media.code,
        });
      }, _media.time * 1000);
    };

    // setMedia(media);
    // setTimeout(() => {
    //   socket.current?.emit("messageFromClient", {
    //     id: media.id,
    //     code: media.code,
    //   });
    // }, media.time * 1000);
  };

  const initSocket = async () => {
    console.log("Connecting to ", process.env.REACT_APP_REAL_TIME_SERVICE_API);
    socket.current?.disconnect();
    socket.current = io(process.env.REACT_APP_REAL_TIME_SERVICE_API || "", {
      // transports: ["websocket"],
      reconnection: true, // whether to reconnect automatically
      reconnectionAttempts: Infinity, // number of reconnection attempts before giving up
      reconnectionDelay: 1000, // how long to initially wait before attempting a new reconnection
      reconnectionDelayMax: 10000, // maximum amount of time to wait between reconnection attempts. Each attempt increases the reconnection delay by 2x along with a randomization factor
      randomizationFactor: 0.5,
      // auth: {
      //   token: token,
      // },
    });
    socket.current.on("message", (data: Media) => {
      if (!data.id) return setMedia(null);
      handleMessage(data);
    });

    socket.current.on("credentials", (data: any) => {
      socket.current?.emit("join", {
        clientCode: "AT5587",
        deviceCode: "879852",
      });
    });

    socket.current.io.on("reconnect_attempt", () => {
      setSocketReconnected(false);
    });

    socket.current.io.on("reconnect", () => {
      setSocketReconnected(true);
    });

    socket.current.io.on("error", (error) => {
      console.log("***ERROR SOCKET", error);
    });
  };

  return (
    <Root>
      <ImageComp source={media?.source || defaultImage} />
    </Root>
  );
}
